import Fuse from "fuse.js";

export async function useBlogPostSearchableList() {
  const route = useRoute();
  const router = useRouter();
  const { data } = await useFetch("/api/blog/posts", {
    query: {
      searchable: true,
    },
  });

  const fuse = new Fuse(data.value ?? [], {
    keys: ["title", "tags"],
    threshold: 0.3,
  });
  const posts = ref(data.value ?? []);
  const query = ref((route.query.query as string) ?? "");

  watch(
    query,
    (value) => {
      posts.value = value
        ? fuse.search(value).map((result) => result.item)
        : (data.value ?? []);
    },
    { immediate: true },
  );

  watch(query, (value) => {
    router.replace({ query: { query: value || undefined } });
  });

  const highlightedPost = computed(() => {
    return data.value?.find((post) => post.pinned) ?? data.value?.[0];
  });

  return {
    query,
    posts: computed(() => {
      if (!query.value)
        return posts.value.filter(
          (post) => post.id !== highlightedPost.value?.id,
        );

      return posts.value;
    }),
    highlightedPost,
  };
}
