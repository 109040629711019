<template>
  <div class="@container">
    <section
      class="relative z-0 grid @2xl:grid-cols-[1fr_48%]"
      :style="{ color: text }"
    >
      <div
        v-if="tag"
        class="absolute inset-0 bottom-0 -z-[1] rounded-lg @2xl:right-8 @6xl:bottom-12 @6xl:right-12"
        :style="{ backgroundColor: background }"
      />

      <div class="relative p-4 @2xl:p-8 @6xl:p-12 @6xl:pb-24">
        <blog-tag v-if="tag" class="mb-1" :tag="tag" />
        <h2
          class="balance line-clamp-6 font-title text-xl/normal @xl:text-2xl/relaxed lg:text-4xl/relaxed"
        >
          {{ post.title }}
        </h2>
        <blog-post-information class="mt-4" :post="post" />
        <app-button
          class="mt-6 w-full @2xl:w-auto"
          color="dark"
          size="lg"
          :to="{ name: 'blog-id', params: { id: post.id } }"
          >{{ $t("blog.post.read_cta") }}</app-button
        >
      </div>

      <div
        class="relative order-first p-4 pb-0 @2xl:order-last @2xl:p-0 @2xl:pt-8 @6xl:pt-12"
      >
        <app-image
          v-if="post.og_image"
          alt=""
          class="relative aspect-video w-full rounded object-cover @2xl:aspect-[1.4] @2xl:rounded-br-[60px] @6xl:rounded-br-[120px]"
          :src="post.og_image"
          :width="1500"
        />
      </div>
    </section>
  </div>
</template>

<script lang="ts" setup>
import BlogPostInformation from "~/pages/blog/_includes/components/blog-post-information.vue";

const properties = defineProps<{
  post: {
    created_at: string;
    id: string;
    title?: string;
    tags?: string[];
    og_image?: string;
  };
}>();

const tag = computed(() => properties.post.tags?.[0]);
const { background, text } = useBlogTagColors(tag.value);
</script>
